import * as React from 'react';
import copy from 'copy-to-clipboard'
import "../styles/App.scss"

const App = () => {
  const str = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890"
  const num = 9
  const url = "https://keydb-dev-transfer.web.app"
  const createKey = () => {
    let key:string = ""
    for (let i = 0; i < num; i++){
      key += str[Math.floor(Math.random() * str.length)]
    }
    return key
  }
  const key = createKey()

  const copyClipbord = () => {
    copy(key)
    alert("コピーしました")
  }

  const jumpLink = () => {
    const link = url + '/?id=' + key
    window.open(link, '_blank')
  } 
  return (
    <div className="root">
      <main className="wrapper">
        <div className="title">
          <h1>keyDB.</h1>
        </div>
        <div  className="description">
          <h2>本人確認をネットで完結させるeKYC</h2>
          <h2>Online KYC</h2>
        </div>
        <div className="key">
          <p>{key}</p>
          <button className="key_button" onClick={() => copyClipbord() }>Copy</button>
        </div>
        <div className="line">
          <p>このサービスは、個人が利用できる本人確認サービスです。ご利用にあたっては、LINEが必要となります。</p>
          <p>Electrical Known Your Customer (eKYC) service. To use this service, you will need LINE.</p>
          <button className="line_button" onClick={() => jumpLink() }>LINEではじめる</button>    
        </div>

      </main>
      <footer>
        <ul>
          <li>
            <a href="https://keydb-dev-support.web.app/privacy" target="_brank">
              プライバシーポリシー
            </a>
          </li>
          <li>
            <a href="https://keydb-dev-support.web.app/terms" target="_brank">
              サービス利用規約
            </a>
          </li>
          <li>
            <a href="https://metamo-ai.com/" target="_brank">
              運営会社
            </a>
          </li>
        </ul>
        <small>
          <p>Copyright Metamo Technologies Inc.</p>
        </small>
      </footer>
    </div>
  )
}

export default App